import axios from "axios";
import jwt from "jsonwebtoken";
import { deleteToken } from "../firebase/firebase.utils";

// 404 error khaitese because jotogula deviceToken ache proti user er shob gula te app currently installed na
// next jedin kaj korbo notification niye shedin jei device gulo te apps installed na shegula deviceToken theke remove kore dite hobe . then baki gula te notification pathate hobe .
export const sendNotifications = async (token, message) => {
  try {
    const serviceAccount = {
      // Replace with your actual service account details
      client_email:
        "firebase-adminsdk-86jnu@alg-frontend.iam.gserviceaccount.com",
      private_key:
        "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDMFchdmPMCT5+4\ndnCN93u1Qeg09DHkPsD8AaS0d8G43TnPaZbUfYTrbCiLdQAeT5NWlA6i3CLXmLOV\nkaTxsfO8XxkgWRKAAi6PuNMHNVrJMArl4PzL532/5tVfzA+YMEtrd4TwJ6A7hYS7\n5uQ2bv54Ha6RjA1EnkuLV1THnQZcV1B85SIikXx/hhOStT9ayBxVYnMB3t+SYoZC\nWzGf55uVvN94bzfxiiwZ+/lS/74a/CVCp5EH3bB237YlOYKzls8nAvqMAD1YiCxE\nKhpuwc8LipCGRxjpHTQy0S6KX/ti3IqKV8YOpYMmaCo5ywDSD5P+IesR9/ssolOo\ndfAsE2wfAgMBAAECggEAAspEFB/shilhTabZgaigKkLFHh1Epd+iSOWbRptlRByp\nGmE2xtwgwqExurVNplkRDZNbZhQe2UwVzNzBKtKHwTsGp+Mp9jCHV251xtqH6I1w\nMmNAElqFBquH/JKHwF1XMBg5gATOdRHcrbg40vjzcUvzbdY74f4JmmmTlG7w9Afx\ngULpbE8aKIE9NWd0Op9CFlMDn1g7CzSQEcs+hO6KZ2GSFlRBV8Leckkbpykd5iRJ\nDrei4NA8n1MHvpSUI8IUuhrlGHYLdkgTlD5N3jiBuakO4fDaN8wl5ZS8e96+7NYl\njE16LEvE6VP85kbvYp4g50Ixfwa3hleGHLoN8/8PMQKBgQDUuTidudA9hLG/B0r5\nx468vdXw39kDUXbzVWTuCPVr429bC0bJVKznnlhL56k9F/8V6DZT7QN29LOIn8Op\n9t5+ixZRT0Grd8EoFt4PpD2yrSsZf+ddx5NBfmpu8r0kLd+4GkBhxiv5Zz/kAEnf\nt/Ie7lD1vxqgL/9SySQBNu5TLwKBgQD1mqqZjLvRsqlAwjwkZPxfOPnMJTUzsCBJ\nU0gGzPuvR2tzt7tAfnEo7ysma9jpbA08kmdWxEK6LdNjfcYYKV/Zsf7cJQMLgK5N\ntEoMQAtD+9SVfBebufM2IUTPw/Kdo/KhD1ZB9qOlPQ/c2kNd6IQEp5cOhlyXuzMp\n4nfO1jz6EQKBgDFss53Z/Ws9uHq0/BOa7SwnQ10K9YYS2HtjHOc9h6BAoK/bp9/B\nX8cVAFjqg2ThfXAemvjW+dYattgIntKPvFRUhqPvsUdgtK1W2tNxirBIEdqD4ELm\nyu0NrTcICTAtxGwnFUxiWfdBqROViZSETaooHwFqHIqZfE5E06vAkpEbAoGAHR1l\npeg6B6TPTZ749DOIe11Mt3eds+/oE+kse6nnGCM2SLR6HS4gCgM4MUjNoPYvJ4QP\nfgisBFgUXBH33rtg4nSoSr6UetOZVhrOQF+DNl7vF31rawmDHGKbPqny498gjxk7\nV3x+b8MqUzsIi2RZ6hdX39/rsA0ABrITE9Dvz5ECgYA1SFw3qXejwD6MSapnJBTO\nB4O6OYHqv8BfTGyYBjT1z84rFAFDCDXR5giTlSuwLJlhG3G/hzbZ9c0jhuROGYu4\nk8YKl2iEaCwW2T1mWhrbPP+rpYWJiyK131dBWySX1Lk7Kus8Q/DHqE0l6XDffY75\noYelYc6a2jqSGq2M8qMd5Q==\n-----END PRIVATE KEY-----\n",
    };

    const now = Math.floor(Date.now() / 1000);
    const payload = {
      iss: serviceAccount.client_email,
      scope: "https://www.googleapis.com/auth/cloud-platform",
      aud: "https://oauth2.googleapis.com/token",
      exp: now + 3600,
      iat: now,
    };
    console.log(payload);

    const signedJwt = jwt.sign(payload, serviceAccount.private_key, {
      algorithm: "RS256",
    });
    console.log(signedJwt);
    // Request the access token
    const response = await axios.post(
      "https://oauth2.googleapis.com/token",
      new URLSearchParams({
        grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
        assertion: signedJwt,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    console.log(response);

    let access_token = response.data.access_token;
    console.log("send notification is called!");

    console.log(access_token);
    var data = JSON.stringify({
      message: {
        token: `${token}`,
        notification: {
          title: message.title,
          body: message.body,
        },
      },
    });

    var config = {
      method: "post",
      url: "https://fcm.googleapis.com/v1/projects/alg-frontend/messages:send",
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json; UTF-8",
      },
      data: data,
    };

    let response2 = await axios(config);
    console.log(response2);
  } catch (error) {
    await deleteToken(token);
    console.log(`couldn't send notifications`);
  }
};
